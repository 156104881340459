import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "IMPAlarmTalkRegisteredCompTable";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 4,
    },
  },
});
const headCells = [
  {
    field: "COMP_C_NUM",
    headerName: "업체부호",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p className="font-weight-bold">{params.data}</p>;
    },
  },
  {
    field: "COMP_NM",
    headerName: "업체명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <p className="font-weight-bold">{params.data}</p>;
    },
  },
];

const alertSetList = [
  {
    key: "CAGA01",
    name: "입항적하목록 제출\n(입항전 수입신고 가능시점)",
    bgColor: "",
  },
  {
    key: "CAGA08",
    name: "입항적하목록 심사완료\n(입항전 수입신고 결제 가능시점)",
    bgColor: "",
  },
  { key: "CAGB01", name: "하선신고 수리", bgColor: "" },
  { key: "CAGB11", name: "하기신고 수리", bgColor: "" },
  { key: "CAGE01", name: "반입신고", bgColor: "" },
  { key: "IMPA01", name: "수입신고", bgColor: "" },
  //   검사대상 선별
  { key: "B", name: "검사대상 선별", bgColor: "" },
  { key: "IMPA17", name: "수입신고 심사진행", bgColor: "" },
  /*
  { key: "IMPA06", name: "수입신고 수리전 정정 완료", bgColor: "" },
  { key: "IMPA21", name: "수입신고 수리후 정정 접수", bgColor: "" },
  { key: "IMPA22", name: "수입신고 수리후 정정 완료", bgColor: "" },
  */
  { key: "IMPA19", name: "수입신고수리", bgColor: "" },
  { key: "CAGE12", name: "수입신고 수리후 반출", bgColor: "" },
];

let filters = {
  SEARCH_LEVEL: "COMP_NM",
  SEARCH_WORD: "",
};

export default function IMPAlarmTalkRegisteredCompTable(props) {
  const { selectedCompList, setCompAlarm, removeComp } = props;

  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("COMP_CD");
  const [sortModel, setSortModel] = useState([
    {
      field: "COMP_CD",
      dir: "asc",
    },
  ]);
  const [dense, setDense] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      () => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    setServerData([...selectedCompList]);
    setTotalCount(parseInt(selectedCompList.length, 10));
    setTotalPageCount(
      parseInt(parseInt(selectedCompList.length, 10) / rowsPerPage, 10) +
        (parseInt(selectedCompList.length, 10) % rowsPerPage > 0 ? 1 : 0)
    );
  }, [selectedCompList]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  }

  function _handleSelectList(isChecked, comp) {
    let dataList = [...selectList];
    if (isChecked) {
      dataList = [...dataList, comp];
    } else {
      const index = dataList.findIndex((data) => data.COMP_CD === comp.COMP_CD);
      dataList.splice(index, 1);
    }
    setSelectList(dataList);
  }

  function _removeList() {
    removeComp(selectList);
    setSelectList([]);
  }

  function searchComp() {
    if (!filters.SEARCH_LEVEL || !filters.SEARCH_WORD) return;
    const searchList = selectedCompList.filter(
      (data) => data[filters.SEARCH_LEVEL].indexOf(filters.SEARCH_WORD) > -1
    );
    setServerData([...searchList]);
    setTotalCount(parseInt(searchList.length, 10));
    setTotalPageCount(
      parseInt(parseInt(searchList.length, 10) / rowsPerPage, 10) +
        (parseInt(searchList.length, 10) % rowsPerPage > 0 ? 1 : 0)
    );
  }

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = { selectList, _handleSelectList, setCompAlarm };

  return (
    <div className="card">
      <div className="card-header bg-light border-bottom">
        <div className="d-flex flex-row justify-content-between">
          <p className="h6 font-weight-bold">등록</p>
          <div>
            <div className="input-group">
              <select
                className="form-control"
                onChange={(e) => {
                  filters["SEARCH_LEVEL"] = e.target.value;
                }}
              >
                <option value="COMP_NM">업체명</option>
                <option value="COMP_C_NUM">업체부호</option>
              </select>
              <input
                className="form-control border min-width-200"
                style={{ height: 40 }}
                type="text"
                placeholder="검색어를 입력해주세요."
                onChange={(e) => {
                  filters["SEARCH_WORD"] = e.target.value;
                }}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    setPage(0);
                    searchComp();
                  }
                }}
              />
              <button
                className="btn btn-secondary"
                onClick={() => {
                  setPage(0);
                  searchComp();
                }}
              >
                검색
              </button>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {serverData
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((row, index) => {
                return (
                  <Row
                    key={row.COMP_CD}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                );
              })}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 51) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 2}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 51) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 2}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 51) * emptyRows }}>
                <TableCell colSpan={headCells.length + 2} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 px-3">
          <div className="d-flex flex-row justify-content-between">
            <button className="btn btn-sm btn-danger" onClick={_removeList}>
              {selectList.length}개 업체 삭제
            </button>
            <Pagination
              style={{ justifyContent: "flex-end" }}
              className={classes.pagination}
              color="primary"
              count={totalPageCount}
              page={page + 1}
              allin={"right"}
              onChange={(event, value) => {
                handleChangePage(event, value - 1);
              }}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </TableContainer>
    </div>
  );
}

function Row(props) {
  const { row, selectList, _handleSelectList, setCompAlarm } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const isChecked =
    selectList.findIndex((data) => data.COMP_CD === row.COMP_CD) > -1;

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        role="checkbox"
        tabIndex={-1}
        key={row.id}
      >
        <TableCell>
          <Checkbox
            color="primary"
            defaultChecked={isChecked}
            onChange={(e) => {
              _handleSelectList(e.target.checked, row);
            }}
          />
        </TableCell>
        {headCells.map((headCell) => {
          const rowProp = {
            data: row[headCell.field],
            currentData: row,
            currentHeader: headCell.field,
            headCells: headCells,
          };
          return (
            <TableCell
              key={headCell.field}
              align={headCell.numeric ? "right" : "left"} //text 정렬
              padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
            >
              {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
                ? headCell.renderCell(rowProp)
                : headCell.hasOwnProperty("valueGetter")
                ? headCell.valueGetter(rowProp)
                : row[headCell.field]}
            </TableCell>
          );
        })}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell
          style={{ padding: 0, background: "rgb(247, 247, 247)" }}
          colSpan={headCells.length + 2}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table size="small" aria-label="purchases">
                <TableBody>
                  <tr>
                    <td>
                      <div className="p-2">
                        <div className="bg-white p-2 pb-0 rounded">
                          <p className="font-weight-bold mb-2">
                            알림받을 정보.
                          </p>
                          <div className="row m-0 p-0 border bg-freiscop-10 font-weight-bold text-center">
                            <div className="col-4 py-2">선택</div>
                            <div className="col-8 py-2 border-left">
                              알림항목
                            </div>
                          </div>
                          {alertSetList.map((set) => {
                            return (
                              <div
                                key={set.key}
                                className="row m-0 p-0 border border-top-0 text-center"
                              >
                                <div className="col-4 py-2">
                                  <input
                                    type="checkbox"
                                    name={"STATE-" + set.key}
                                    className="ml-1"
                                    defaultChecked={
                                      Number(row["STATE"]?.[set.key]) > 0
                                    }
                                    onChange={(e) => setCompAlarm(e, row)}
                                  />
                                </div>
                                <div className="col-8 py-2 border-left">
                                  <p>{set.name}</p>
                                </div>
                              </div>
                            );
                          })}
                          {/*
                      <div className="d-flex flex-row align-items-center mb-2">
                        <p className="min-width-100 font-weight-bold mr-3">
                          업무구분.
                        </p>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          IMPORT LIST
                          <input
                            type="checkbox"
                            name="WORK-IMP"
                            className="ml-1"
                            defaultChecked={Number(row.WORK.IMP) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          CUSTOMS
                          <input
                            type="checkbox"
                            name="WORK-CUSTOMS"
                            className="ml-1"
                            defaultChecked={Number(row.WORK.CUSTOMS) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-2">
                        <p className="min-width-100 font-weight-bold mr-3">
                          알림받을 정보.
                        </p>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          입항안내
                          <input
                            type="checkbox"
                            name="TRANS-ARRIVAL"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.ARRIVAL) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          수입신고
                          <input
                            type="checkbox"
                            name="TRANS-DECLARATION"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.DECLARATION) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          검사여부
                          <input
                            type="checkbox"
                            name="TRANS-INSPECT"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.INSPECT) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          통관완료
                          <input
                            type="checkbox"
                            name="TRANS-COMPLETED"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.COMPLETED) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          반출완료
                          <input
                            type="checkbox"
                            name="TRANS-CARGO"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.CARGO) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          배차정보
                          <input
                            type="checkbox"
                            name="TRANS-DELIVERY"
                            className="ml-1"
                            defaultChecked={Number(row.TRANS.DELIVERY) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                      </div>
                      <div className="d-flex flex-row align-items-center">
                        <p className="min-width-100 font-weight-bold mr-3">
                          오더정보.
                        </p>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          통관의뢰
                          <input
                            type="checkbox"
                            name="ORDER-CUSTOMS"
                            className="ml-1"
                            defaultChecked={Number(row.ORDER.CUSTOMS) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          운송의뢰
                          <input
                            type="checkbox"
                            name="ORDER-TRANS"
                            className="ml-1"
                            defaultChecked={Number(row.ORDER.TRANS) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                        <label className="d-flex flex-row align-items-center border py-1 px-2 m-0 mr-2">
                          보험의회
                          <input
                            type="checkbox"
                            name="ORDER-INSUR"
                            className="ml-1"
                            defaultChecked={Number(row.ORDER.INSUR) > 0}
                            onChange={(e) => setCompAlarm(e, row)}
                          />
                        </label>
                      </div>
                      */}
                        </div>
                      </div>
                    </td>
                  </tr>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        {headCells.map((headCell, index) => (
          <TableCell
            key={`${headCell.field}_${index}`}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}
