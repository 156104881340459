import React, { useCallback, useEffect, useRef, useState } from "react";
import { ResponsivePie } from "@nivo/pie";
import axios from "axios";

/*
*
      DISK_USAGE: 28.34,
      CPU_USAGE: 28.34,
      NETWORK_TRAFFIC: 28.34,
* */

let sample = [
  {
    SERVER_NAME: "GBTS",
    charts: [
      [
        {
          id: "DISK USAGE",
          value: 28.34,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "CPU USAGE",
          value: 54.87,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "NETWORK TRAFFIC",
          value: 46.33,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
    ],
  },
  {
    SERVER_NAME: "logi_talktalk",
    charts: [
      [
        {
          id: "DISK USAGE",
          value: 13.57,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "CPU USAGE",
          value: 23.33,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "NETWORK TRAFFIC",
          value: 14.34,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
    ],
  },
  {
    SERVER_NAME: "SAMPLE SERVER",
    charts: [
      [
        {
          id: "DISK USAGE",
          value: 13.57,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "CPU USAGE",
          value: 23.33,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "NETWORK TRAFFIC",
          value: 14.34,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
    ],
  },
  {
    SERVER_NAME: "SAMPLE SERVER 2",
    charts: [
      [
        {
          id: "DISK USAGE",
          value: 13.57,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "CPU USAGE",
          value: 23.33,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "NETWORK TRAFFIC",
          value: 14.34,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
    ],
  },
  {
    SERVER_NAME: "SAMPLE SERVER 3",
    charts: [
      [
        {
          id: "DISK USAGE",
          value: 13.57,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "CPU USAGE",
          value: 23.33,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
      [
        {
          id: "NETWORK TRAFFIC",
          value: 14.34,
          color: "#0097B7",
        },
        {
          id: "AMOUNT",
          value: 100,
          color: "#334D80",
        },
      ],
    ],
  },
];

export default function Test() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [pieSize, setPieSize] = useState(0);

  function setResize() {
    const rowCnt = Math.ceil(sample.length / 4);
    const pieHeight = Math.floor(
      (window.innerHeight - 88) / rowCnt - rowCnt * 160
    );
    const pieWidth = Math.floor(window.innerWidth / 8 - 32 * 4);

    let temp = pieHeight;
    if (temp > pieWidth) {
      temp = pieWidth;
    }
    if (temp < 0) {
      temp = pieHeight > pieWidth ? pieHeight : pieWidth;
    }

    setPieSize(temp);
  }
  useEffect(() => {
    setResize();
    window.addEventListener("resize", setResize);
    return () => {
      window.removeEventListener("resize", setResize);
    };
  }, []);

  // useEffect(() => {
  //   const intervalRequest = setInterval(() => {
  //     test();
  //   }, 1000 * 10); // * 20
  //
  //   return () => {
  //     clearInterval(intervalRequest);
  //   };
  // }, []);

  function getHardwareMonitor() {
    const username = "admin"; // .env 파일의 VALID_USERS에 정의된 사용자 이름
    const password = "GbtS9880!@"; // .env 파일의 VALID_USERS에 정의된 비밀번호
    const base64Credentials = Buffer.from(`${username}:${password}`).toString(
      "base64"
    );
    const authHeader = `Basic ${base64Credentials}`;
    axios
      .get("https://system.gbts.co.kr:9910/monitor", {
        headers: {
          Authorization: authHeader,
        },
      })
      .then((response) => {
        console.log("Monitoring Info:", response.data);
      })
      .catch((error) => {
        console.error(
          "Error fetching monitoring information:",
          error.response ? error.response.data : error.message
        );
      });

    let sampleData = {
      cpu: { load: 88.9763779527559, cores: 4 },
      memory: {
        free: 1359081472,
        total: 16982241280,
        used: 15623159808,
        percentUsed: 91.99704297217475,
      },
      disks: [
        {
          fs: "C:",
          type: "NTFS",
          size: 103076065280,
          used: 91786010624,
          available: 11290054656,
          use: 89.05,
          mount: "C:",
          rw: true,
        },
      ],
      uptime: 94051617.031,
      os: { platform: "win32", release: "10.0.14393", arch: "x64" },
    };
  }

  function test() {
    for (let i = 0; i < sample.length; i++) {
      for (let j = 0; j < sample[i].charts.length; j++) {
        sample[i].charts[j][0].value = Math.floor(Math.random() * 100) + 1;
        sample[i].charts[j][1].value = 100 - sample[i].charts[j][0].value;
      }
    }
    setIsNeedUpdate(!isNeedUpdate);
  }
  return (
    <main style={{ height: "100vh" }} className="bg-freiscop text-white">
      <div className="vh-100 d-flex flex-column">
        <p className="h2 font-weight-bold mb-0 px-2 py-3">
          DASH BOARD
          <button onClick={test}>test</button>
          <button onClick={getHardwareMonitor}>test2</button>
        </p>
        <div className="d-flex flex-row flex-wrap">
          <div
            className="mh-100 w-100 row m-0 p-0"
            style={{ maxHeight: `${window.innerHeight - 88} !important` }}
          >
            {sample.map((server, index) => {
              return (
                <div key={index} className="col-4 p-2">
                  <div
                    className="p-3 rounded-lg shadow"
                    style={{ background: "rgba(255,255,255,0.05)" }}
                  >
                    <p className="h4 mb-0 font-weight-bold">
                      <i className="fa-solid fa-circle-info mr-2 text-light" />
                      {server.SERVER_NAME}
                    </p>
                    <div className="row m-0 p-0">
                      {server.charts.map((chart, index) => {
                        const type = chart[0].id;
                        const value = chart[0].value;
                        return (
                          <div key={index} className="col-4 m-0 p-2">
                            <p className="text-light h6 mb-0 font-weight-bold">
                              {type}{" "}
                            </p>
                            <p
                              className="mb-2"
                              style={{ color: "rgba(255,255,255,0.6)" }}
                            >
                              Chart description
                            </p>
                            <div
                              className="position-relative w-100"
                              style={{ height: pieSize }}
                            >
                              <p
                                className="position-absolute h3 mb-0 font-weight-bold position-center"
                                style={{ fontSize: pieSize / 6 }}
                              >
                                {value}%
                              </p>
                              <div
                                className="position-absolute position-center"
                                style={{ height: pieSize, width: pieSize }}
                              >
                                <MyResponsivePie
                                  data={chart}
                                  length={pieSize}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
}

const MyResponsivePie = ({ data /* see data tab */, innerRadius, length }) => {
  return (
    <ResponsivePie
      data={data}
      colors={(event) => {
        return event.data.color;
      }}
      width={length}
      height={length}
      startAngle={0}
      endAngle={360}
      innerRadius={innerRadius || 0.8}
      enableArcLabels={false}
      enableArcLinkLabels={false}
    />
  );
};
