import React, { useCallback, useEffect, useMemo, useState } from "react";

import ChatNewsDetail from "./ChatNewsDetail";
import GBTSLogoSvg from "../../assets/GBTSLogoSvg";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const _mClassName = "ChatNews";

let likePostList = {};

export default function ChatNews(props) {
  const { loginInfo, subscribeList } = props;
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(12);

  const [sortModel, setSortModel] = useState([
    {
      field: "cntCreatDate",
      dir: "desc",
    },
  ]);

  const classes = useStyles();

  useEffect(() => {
    requestUserLikeList((newData) => {
      for (let i = 0; i < newData["returnData"].length; i++) {
        likePostList[newData["returnData"][i].cntlMSGKey] = "";
      }
      requestNewsList();
    });

    socket.addLocalEventListener("SendNews", _mClassName, (b, n) => {
      if (!n) return;
      requestNewsList();
    });

    return () => {
      socket.removeLocalEventListener("SendNews", _mClassName);
    };
  }, []);

  useEffect(() => {
    requestNewsList();
  }, [page, subscribeList]);

  const requestUserLikeList = (callback) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_getLikeListWithUser.php",
      msgID: msgID,
      data: {},
      cntlCreator: loginInfo.user_id,
      cntlCreatorSite: loginInfo.comp_cd,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 뉴스목록 요청
  const requestNewsList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cntDelYN: "N",
      isLogiTalk: "Y",
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const clickSubscribe = (news) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_TALK_NEWS_SUBSCRIBE_CHANGED,
      _mClassName,
      news,
      () => {}
    );
  };

  const updateLikeStatusNewsWithUser = (type, updateData) => {
    const filePath = {
      INSERT: "/talk_news/JS_insertLikeChatNews.php",
      DELETE: "/talk_news/JS_deleteLikeChatNews.php",
    };
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: filePath[type],
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (type === "INSERT") {
            likePostList[updateData.cntlMSGKey] = "";
          }
          if (type === "DELETE") {
            delete likePostList[updateData.cntlMSGKey];
          }

          if (updateData.cntlMSGKey === selectedItem.cntMSGKey) {
            setSelectedItem((prevState) => {
              let nextState = { ...prevState };
              let cntLikeTotal = Number(nextState.cntLikeTotal);
              if (type === "INSERT") {
                ++cntLikeTotal;
              } else {
                --cntLikeTotal;
              }

              nextState.cntLikeTotal = cntLikeTotal;
              return nextState;
            });
          }
          requestNewsList();
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const ItemRender = useCallback(
    (props) => {
      return <Item {...props} />;
    },
    [subscribeList]
  );

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  if (selectedItem !== undefined) {
    return (
      <ChatNewsDetail
        loginInfo={loginInfo}
        data={selectedItem}
        subscribeList={subscribeList}
        likePostList={likePostList}
        updateLikeStatusNewsWithUser={updateLikeStatusNewsWithUser}
      />
    );
  }

  return (
    <div className="p-3 w-100 h-100 overflow_scroll-Y bg-white">
      <div className="h-100 d-flex flex-column justify-content-between">
        <div>
          <div className="mb-2">
            <p className="h5 font-weight-bold text-info mb-0">
              로지톡 물류 소식
            </p>
            <p>
              최신 물류소식을 만나고 한 눈에 보고 알림톡 채널을 구독해 유용한
              소식을 "실시간"으로 받아보세요
            </p>
          </div>
          <div className="row m-0 p-0">
            {data.length > 0
              ? data.map((item, index) => {
                  return (
                    <ItemRender
                      key={item.id}
                      item={item}
                      subscribeList={subscribeList}
                      clickSubscribe={clickSubscribe}
                    />
                  );
                })
              : ""}
          </div>
        </div>
        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </div>
  );
}

const Item = React.memo((props) => {
  const { item, subscribeList, clickSubscribe } = props;
  const isSubscribe = subscribeList.hasOwnProperty(item.cntMainKey);
  const cntLikeTotal = Number(item.cntLikeTotal);
  const picMain = item.cntPic ? item.cntPic.split("?")[0] : false;
  const [year, month, date] = util
    .getDateToFormat(item.cntCreatDate, "YYYY-MM-DD")
    .split("-");

  return (
    <div className="col-12 col-md-6 col-lg-3 p-2">
      <div className="bg-white cursor_pointer">
        <div
          className="p-2"
          onClick={() => {
            if (window.location.host === "system.gbts.co.kr") {
              window.open(`/LogiTalk/ChatNewsDetail/${item.id}`);
              return;
            }
            window.open(`/ChatNewsDetail/${item.id}`);
          }}
        >
          {picMain && (
            <div
              className="min-height-200 rounded-lg"
              style={{
                background: `url(${picMain}) 50% 50% / cover no-repeat`,
              }}
            />
          )}
          {!picMain && (
            <div className="child-center min-height-200">
              <GBTSLogoSvg />
            </div>
          )}
        </div>
        <div className="p-2 d-flex flex-row justify-content-between">
          <div
            className="flex-fill"
            onClick={() => {
              if (window.location.host === "system.gbts.co.kr") {
                window.open(`/LogiTalk/ChatNewsDetail/${item.id}`);
                return;
              }
              window.open(`/ChatNewsDetail/${item.id}`);
            }}
          >
            <p className="h5 text-secondary font-weight-bold mb-1 ellipsis_1">
              {item.cntTitle}
            </p>
            <p className="font-size-08">
              {year}년 {month}월 {date}일
              {cntLikeTotal > 0 && (
                <span className="font-weight-bold">
                  {" "}
                  · 좋아요 {cntLikeTotal}{" "}
                </span>
              )}
            </p>
          </div>
          <div className="pl-3 text-right">
            <button
              className="bg-transparent border-0 p-0 font-weight-bold text-freiscop"
              style={{ minWidth: 50 }}
              onClick={() => clickSubscribe(item)}
            >
              <span className="mr-2">구독</span>
              <i
                className={
                  isSubscribe
                    ? "fa-solid fa-bookmark"
                    : "fa-regular fa-bookmark"
                }
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
