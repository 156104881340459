import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import LogoSvg from "../assets/LogoSvg";

import { CountUp } from "../lib/CountUp";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "MainService";

let amount = {
  FCL: 0,
  FCL2: 0,
  LCL: 0,
  AIR: 0,
  TOTAL: 0,
};
let promotion_total = 0;
let promotion_list = [];
let isMobileBack = false;
export default function MainService() {
  const [isLoading, setIsLoading] = useState(true);
  const countUpFCL = useRef();
  const countUpLCL = useRef();
  const countUpAIR = useRef();
  let countUpAnimFCL;
  let countUpAnimLCL;
  let countUpAnimAIR;

  useEffect(() => {
    SELECT_SCHEDULE_AMOUNT();
  }, []);

  useEffect(() => {
    if (countUpFCL.current && !isLoading) {
      initCountUpFCL();
    }
    if (countUpLCL.current && !isLoading) {
      initCountUpLCL();
    }
    if (countUpAIR.current && !isLoading) {
      initCountUpAIR();
    }
  }, [isLoading]);

  async function initCountUpFCL() {
    countUpAnimFCL = new CountUp(
      countUpFCL.current,
      parseInt(amount.FCL, 10) + parseInt(amount.FCL2, 10)
    );

    if (!countUpAnimFCL.error) {
      countUpAnimFCL.start();
    } else {
      console.error(countUpAnimFCL.error);
    }
  }

  async function initCountUpLCL() {
    countUpAnimLCL = new CountUp(countUpLCL.current, parseInt(amount.LCL));
    if (!countUpAnimLCL.error) {
      countUpAnimLCL.start();
    } else {
      console.error(countUpAnimLCL.error);
    }
  }

  async function initCountUpAIR() {
    countUpAnimAIR = new CountUp(countUpAIR.current, parseInt(amount.AIR));
    if (!countUpAnimAIR.error) {
      countUpAnimAIR.start();
    } else {
      console.error(countUpAnimAIR.error);
    }
  }

  //스케줄 개수 받아오기
  function SELECT_SCHEDULE_AMOUNT() {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_count_select.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          amount = { ...newData["returnData"][0] };
          requestSchedulePromotion();
        }
      }
    });
  }

  //프로모션 받아오기
  const requestSchedulePromotion = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/schedule/JS_schedule_promotion_by_main_select.php",
      msgID: msgID,
      data: {},
      skip: 0,
      page: 1,
      take: 6,
      sp_DISPLAY: 0,
      sm_USE_YN: "Y",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          promotion_list = [...newData["returnData"]];
          promotion_total = parseInt(newData.pageSize, 10);
          setIsLoading(false);
        }
      }
    });
  };

  const PromoItem = ({ item }) => {
    return (
      <div className="col-6 col-md-4 m-0 p-0 pt-1 pr-1">
        <Link
          to={
            item.sp_LCL_FCL === "LCL"
              ? "/ScheduleLCL"
              : item.sp_LCL_FCL === "FCL"
              ? "/ScheduleFCL"
              : "/ScheduleAIR"
          }
          state={{
            type: "promo",
            sm_POL_CODE: item.sm_POL_CODE,
            sm_POD_CODE: item.sm_POD_CODE,
            ORIGIN: item.sm_ORIGIN,
            DEST: item.sm_DEST,
          }}
          className="d-inline-block rounded p-2 w-100 border border-light shadow-sm text-dark cursor_pointer_shadow text-decoration-none"
        >
          <div className="d-flex flex-row align-items-center pb-2 border-bottom">
            <div
              className="mr-1"
              style={{
                width: 30,
                height: 30,
                background: `url(${item.COMP_LOGO}) 50% 50%/contain no-repeat`,
              }}
            />
            <div>
              <p className="font-weight-bold ellipsis_1 font-size-08">
                {item.COMP_NM.trim()}
              </p>
              <p className="text-info font-weight-bold ellipsis_1 font-size-09">
                {item.sm_POL_NAME} → {item.sm_POD_NAME}
              </p>
            </div>
          </div>
          <p className="pt-2 font-weight-900 text-secondary ellipsis_2 font-size-09">
            {item.sm_PROMO_MEMO}
          </p>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="bg-white margin_30 NNS" id="mainService">
        <div className="container">
          <div className="row m-0 p-0">
            <div className="col-xl-6 d-flex flex-column m-0 pb-3 pr-0 pr-xl-3 pl-0 ">
              <div className="my-2">
                <p className="h4 font-weight-bold d-flex flex-row align-items-end flex-wrap position-relative">
                  <span className="mr-2">
                    <LogoSvg width="150px" />
                  </span>
                  <span className="text-info">프레이스콥</span>
                </p>
                <p className="h5">
                  전세계 해상, 항공 물류 스케쥴과 운임을 조회하고 간편하게{" "}
                  <b>BOOKING(선복예약)</b>하세요.
                </p>
                {/*
                <p>
                  제휴된 선사와 콘솔사의{" "}
                  <span className="text-freiscop font-weight-900">
                    공식 E-Booking 채널
                  </span>
                  입니다.
                </p>
                */}
                <div className="row m-0 px-0 py-3 font-weight-900">
                  <div className="d-block d-lg-none col-6 col-lg-3 m-0 p-0 py-1 text-center border-bottom">
                    ALL IN ONE 스케쥴
                  </div>
                  <div className="d-none d-lg-block col-6 col-lg-3 m-0 p-0 py-1 text-center">
                    ALL IN ONE 스케쥴
                  </div>
                  <div className="d-block d-lg-none col-6 col-lg-3 m-0 p-0 py-1 text-center border-left border-bottom">
                    할인운임 예약
                  </div>
                  <div className="d-none d-lg-block col-6 col-lg-3 m-0 p-0 py-1 text-center border-left">
                    할인운임 예약
                  </div>
                  <div className="d-block d-lg-none col-6 col-lg-3 m-0 p-0 py-1 text-center">
                    예약 마일리지 적립
                  </div>
                  <div className="d-none d-lg-block col-lg-3 m-0 p-0 py-1 text-center border-left">
                    예약 마일리지 적립
                  </div>
                  <div className="col-6 col-lg-3 m-0 p-0 py-1 text-center border-left">
                    통합 예약관리
                  </div>
                </div>
                <div className="h6 mb-1 d-flex flex-row align-items-center flex-wrap mb-2">
                  <span className="position-relative h5 mb-0 font-weight-900 mr-1 mt-2">
                    해상 FCL
                    <div
                      className="position-absolute w-100 h-50 bg-freiscop"
                      style={{
                        bottom: 0,
                        opacity: 0.2,
                        transform: "skew(-20deg)",
                      }}
                    />
                  </span>

                  <span className="mr-1 mt-2">
                    총{" "}
                    <span
                      className="text-info font-weight-900"
                      ref={countUpFCL}
                    >
                      0
                    </span>
                    <span className="text-info font-weight-900">건</span>의
                    스케쥴이 있습니다
                  </span>

                  {/*
                  <span className="mr-1 mt-2">
                    총{" "}
                    <span className="text-info font-weight-900">
                      {util.addCommas(
                        parseInt(amount.FCL, 10) + parseInt(amount.FCL2, 10)
                      )}
                      건
                    </span>
                    의 스케쥴이 있습니다
                  </span>*/}

                  <Link
                    to="/ScheduleFCL"
                    state={{ type: "FCL" }}
                    className="btn_1 font-size-06 bg-freiscop px-2 py-1 mt-2 rounded-pill"
                  >
                    검색하기
                    <i className="fa-solid fa-arrow-right ml-1" />
                  </Link>
                </div>
                <div className="h6 mb-1 d-flex flex-row align-items-center flex-wrap mb-2">
                  <span className="position-relative h5 mb-0 font-weight-900 mr-1 mt-2">
                    해상 LCL
                    <div
                      className="position-absolute w-100 h-50 bg-freiscop"
                      style={{
                        bottom: 0,
                        opacity: 0.2,
                        transform: "skew(-20deg)",
                      }}
                    />
                  </span>
                  <span className=" mr-1 mt-2">
                    총{" "}
                    <span
                      className="text-info font-weight-900"
                      ref={countUpLCL}
                    >
                      0
                    </span>
                    <span className="text-info font-weight-900">건</span>의
                    스케쥴이 있습니다
                  </span>
                  <Link
                    to="/ScheduleLCL"
                    state={{ type: "LCL" }}
                    className="btn_1 font-size-06 bg-freiscop px-2 py-1 mt-2 rounded-pill"
                  >
                    검색하기
                    <i className="fa-solid fa-arrow-right ml-1" />
                  </Link>
                </div>
                <div className="h6 mb-1 d-flex flex-row align-items-center flex-wrap mb-2">
                  <span className="position-relative h5 mb-0 font-weight-900 mr-1 mt-2">
                    항공 AIR
                    <div
                      className="position-absolute w-100 h-50 bg-freiscop"
                      style={{
                        bottom: 0,
                        opacity: 0.2,
                        transform: "skew(-20deg)",
                      }}
                    />
                  </span>
                  <span className=" mr-1 mt-2">
                    총{" "}
                    <span
                      className="text-info font-weight-900"
                      ref={countUpAIR}
                    >
                      0
                    </span>
                    <span className="text-info font-weight-900">건</span>의
                    스케쥴이 있습니다
                  </span>
                  <Link
                    to="/ScheduleAIR"
                    state={{ type: "AIR" }}
                    className="btn_1 font-size-06 bg-freiscop px-2 py-1 mt-2 rounded-pill"
                  >
                    검색하기
                    <i className="fa-solid fa-arrow-right ml-1" />
                  </Link>
                </div>
              </div>
              <div
                className="flex-grow-1 w-100 mt-2 rounded shadow-sm"
                style={{
                  background: `url(/img/use_img/main_freiscop_600.jpg) 50% 70% /cover no-repeat`,
                  height: 250,
                }}
              />
            </div>
            <div className="col-xl-6 d-flex flex-column m-0 py-3 pl-0 pl-xl-3 pr-0 ">
              <div
                className="w-100 mb-2 rounded shadow-sm order-3 order-xl-0 mt-3 mt-xl-0"
                style={{
                  background: `url(/img/use_img/main_promotion_600.jpg) 50% 70% /cover no-repeat`,
                  height: 250,
                }}
              />
              <div className="pt-3 flex-grow-1 d-flex flex-column">
                <p className="h5 font-weight-bold d-flex flex-row align-items-end flex-wrap">
                  <span className="font-weight-900 mr-2">
                    추천 선사(항공사) & 프로모션
                  </span>

                  <span className="mb-0 mr-1" style={{ fontSize: "13px" }}>
                    총{" "}
                    <span className="text-danger font-weight-bold">
                      {promotion_total}건
                    </span>
                    의 프로모션이 있습니다
                  </span>
                  <span className="h6 m-0">
                    <Link
                      to="/ScheduleFCL"
                      state={{ type: "promo" }}
                      className="btn_1 font-size-06 bg-danger border-0 px-2 py-1 mt-2 rounded-pill"
                    >
                      검색하기
                      <i className="fa-solid fa-arrow-right ml-1" />
                    </Link>
                  </span>
                </p>

                {promotion_list.length > 0 ? (
                  <div className="flex-grow-1 row m-0 p-0 mt-2">
                    {promotion_list.map((item, index) => {
                      let data = { ...item };

                      if (item.COMP_LOGO.indexOf("?") > -1) {
                        data.COMP_LOGO = item.COMP_LOGO.split("?").find(
                          (item) => item.indexOf("http") > -1
                        );
                      }

                      return <PromoItem key={index} item={data} />;
                    })}
                  </div>
                ) : (
                  <div className="flex-grow-1 row m-0 p-0 mt-2">
                    <div className="col-12 bg-light p-4 m-0 font-weight-bold text-secondary d-flex flex-row justify-content-center align-items-center">
                      등록된 프로모션 물류상품이 존재하지 않습니다.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
