import React from "react";

export default function BestCustomsFooter() {
  return (
    <footer className="d-flex flex-column align-items-center text-secondary bg-light py-4">
      <p className="font-weight-bold">상호: 베스트관세법인 ｜ 대표: 서창길</p>
      <br />
      <p>주소: 인천광역시 중구 흰바위로59번길8, 220호 (운서동)</p>
      <p>사업자등록번호: 252-81-03247</p>
      <p>대표전화 : 032-751-9870 ｜ 팩스: 032-751-9865</p>
      <br />
      <p>
        Copyright <span className="font-weight-bold">베스트관세법인</span> Corp.
        All Rights Reserved.
      </p>
    </footer>
  );
}
