import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

import Pagination from "@mui/material/Pagination/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import { Link } from "react-router-dom";
import BestCustomsFooter from "./BestCustomsFooter";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useStyles = makeStyles((theme) => ({
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

export default function PublicReport() {
  const classes = useStyles();
  const { history, location } = window;
  const [isScroll, setIsScroll] = useState(false);
  const [isWeb, setIsWeb] = useState(true);
  const [selectedReport, setSelectedReport] = useState(null);
  const [serverData, setServerData] = useState([]);
  const [sideNewsList, setSideNewsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [sortModel, setSortModel] = useState([
    {
      field: "cntCreatDate",
      dir: "desc",
    },
  ]);

  const SEARCH_WORD = useRef();
  const controller = {
    swrUserEmail: useRef(),
    swrTermsYN: useRef(),
  };

  useEffect(() => {
    const preventGoBack = () => {
      // change start
      history.pushState(null, "", location.href);
      // change end
      console.log("prevent go back!");
    };

    history.pushState(null, "", location.href);
    window.addEventListener("popstate", preventGoBack);

    window.addEventListener("scroll", windowScrollEvent);
    window.addEventListener("resize", windowResizeEvent);

    return () => {
      window.removeEventListener("scroll", windowScrollEvent);
      window.removeEventListener("resize", windowResizeEvent);
      window.removeEventListener("popstate", preventGoBack);
    };
  }, []);

  useEffect(() => {
    requestNewsList();
  }, [page]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [serverData]);

  function windowScrollEvent() {
    setIsScroll(window.scrollY > 70);
  }
  function windowResizeEvent() {
    if (window.innerHeight < 900) {
      document.querySelector("#fixed").className = "";
    } else {
      document.querySelector("#fixed").className = "position-fixed";
    }
    setIsWeb(window.innerWidth >= 1200);
  }

  function requestNewsList() {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cntDelYN: "N",
      isLogiTalk: "Y",
      SEARCH_WORD: SEARCH_WORD.current.value || "",
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (sideNewsList.length === 0) setSideNewsList(newData["returnData"]);
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  }

  function actionSearch() {
    setPage(0);
    requestNewsList();
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function subscribeWeeklyReport() {
    if (controller.swrUserEmail.current.value.trim() === "") {
      codeList.Modal.current.alert("이메일 주소를 입력해주세요.", () => {
        controller.swrUserEmail.current.focus();
      });
      return;
    }
    if (!util.validationEmail(controller.swrUserEmail.current.value)) {
      codeList.Modal.current.alert("유효하지 않은 이메일주소입니다.", () => {
        controller.swrUserEmail.current.focus();
      });
      return;
    }

    if (!controller.swrTermsYN.current.checked) {
      codeList.Modal.current.alert(
        "개인정보 수집 · 이용에 동의해주세요.",
        () => {
          controller.swrTermsYN.current.focus();
        }
      );
      return;
    }
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/weeklyReport/JS_insertConcatWeeklyReport.php",
      msgID: msgID,
      data: {},
      swrUserEmail: controller.swrUserEmail.current.value,
      swrTermsYN: controller.swrTermsYN.current.checked ? "Y" : "N",
      swrAgreeYN: "Y",
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "베스트관세법인 컨설팅 본부 주간 Report 구독이 완료되었습니다."
          );
        } else {
          if (Number(newData["cnt"]) > 0) {
            codeList.Modal.current.alert("이미 구독되어있는 이메일주소입니다.");
          } else {
            codeList.Modal.current.alert(
              "오류가 발생했습니다. 다시 시도해주세요."
            );
          }
        }
      }
    });
  }

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const ReportListItem = useCallback(
    React.memo(({ report, index }) => {
      const [picLink, picName] = report.cntPic
        ? report.cntPic.split("?")
        : report.cnmPicMain.split("?");
      return (
        <Link
          className="text-secondary bg-white rounded-lg p-3 shadow-sm mb-3 cursor_pointer cursor_pointer_bg_light d-flex flex-row align-items-top"
          to={`/PublicReportDetail/${report.id}`}
        >
          <img
            className="height-150 rounded-lg mb-2"
            src={picLink}
            alt={picName}
            style={{ objectFit: "cover" }}
          />
          <div className="pl-3 pr-1">
            <p className="h5 mb-2 font-weight-900">{report.cntTitle}</p>
            <p className="h6 mb-0">
              {util.getDateToFormat(report.cntCreatDate, "YYYY-MM-DD")}
            </p>
          </div>
        </Link>
      );
    }),
    []
  );

  const NewNewsList = useCallback(
    React.memo(({ report, index }) => {
      return (
        <div className="p-3 rounded-lg bg-white mb-4 w-100 border">
          <p className="h6 font-weight-900">NEW 게시물</p>

          {sideNewsList.length > 0 &&
            sideNewsList.map((report, index) => (
              <Link
                key={report.id}
                className="d-flex flex-row justify-content-between align-items-center py-1 cursor_pointer cursor_pointer_bg_light text-dark"
                to={`/PublicReportDetail/${report.id}`}
              >
                <p className="flex-grow-1 ellipsis_1">{report.cntTitle}</p>
                <p className="min-width-90 ml-2 text-right">
                  {util.getDateToFormat(report.cntCreatDate, "YYYY-MM-DD")}
                </p>
              </Link>
            ))}
        </div>
      );
    }),
    [sideNewsList]
  );
  return (
    <main
      className="bg-white d-flex flex-column justify-content-between"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <div className="container pt-4">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="h4 font-weight-900 text-dark">
              <img
                className="mr-2 cursor_pointer"
                style={{ height: "1em" }}
                src="https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/gbts/companyInfo/20221207180941_베스트로고검정로고만.png"
                alt="logo"
                onClick={() => {
                  window.location.href = "http://www.bestcustoms.kr/";
                }}
              />
              컨설팅 본부 <span className="text-danger">주간 Report.</span>
            </p>
            <div>
              {selectedReport && (
                <span
                  className="cursor_pointer cursor_pointer_fw_bold h5 mb-0"
                  onClick={() => {
                    setSelectedReport(null);
                  }}
                >
                  뒤로가기 &times;
                </span>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="row d-xl-none">
            <div className="col-12 d-xl-none">
              <div className="container">
                <NewNewsList />
              </div>
            </div>
            {!isWeb && (
              <div className="col-12 d-xl-none mb-3 py-3 bg-blue-light">
                <div className="container">
                  <p className="h6 font-weight-bold mb-3">
                    <span className="font-weight-900">
                      베스트관세법인 컨설팅본부
                    </span>
                    에서 발행하는{" "}
                    <span className="font-weight-900 text-danger">
                      관세와 물류에 관한 분석보고서
                    </span>
                    를 받아보세요. <br />
                    <span className="font-size-08">
                      단순 소식지가 아닌 전문가의 분석과 인사이트가 포함된
                      통관/물류 전문리포트입니다.
                    </span>
                  </p>
                  <div className="mb-3">
                    <input
                      className="form-control border-0 mb-1"
                      type="email"
                      placeholder="이메일 주소를 입력해주세요."
                      ref={controller.swrUserEmail}
                    />
                    <label className="d-flex flex-row align-items-center px-1">
                      <input
                        type="checkbox"
                        className="mr-2"
                        ref={controller.swrTermsYN}
                      />
                      <span className="text-danger font-weight-bold">
                        개인정보 수집 · 이용
                      </span>
                      에 동의해주세요.
                    </label>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-end">
                    <a
                      className="text-secondary"
                      href="https://www.gbts.co.kr//backside/weeklyReport/cancel_concat_weekly_report.php"
                      target="_blank"
                    >
                      구독 취소
                    </a>
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={subscribeWeeklyReport}
                    >
                      무료로 구독하기
                      <i className="fa-regular fa-hand-pointer ml-2" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="container pb-4">
            <div className="row">
              <div className="col-12 col-xl-8">
                <div className="input-group mb-2">
                  <input
                    ref={SEARCH_WORD}
                    className="form-control border"
                    placeholder="검색어를 입력해주세요"
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionSearch();
                      }
                    }}
                  />
                  <button className="btn btn-secondary" onClick={actionSearch}>
                    검색
                  </button>
                </div>
                {data.length === 0 && !loading && (
                  <div className="bg-white rounded-lg py-5 px-2">
                    <p className="font-weight-bold text-secondary text-center">
                      등록된 Report가 존재하지 않습니다.
                    </p>
                  </div>
                )}
                {data.length > 0 &&
                  data.map((report, index) => (
                    <ReportListItem
                      key={report.id}
                      report={report}
                      index={index}
                    />
                  ))}

                <div className="mb-3 mt-3 pr-3">
                  <Pagination
                    style={{ justifyContent: "flex-end" }}
                    className={classes.pagination}
                    count={totalPageCount}
                    page={page + 1}
                    onChange={(event, value) => {
                      handleChangePage(event, value - 1);
                    }}
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
              <div className="col-4 d-none d-xl-block position-relative">
                <div
                  id="fixed"
                  className="position-fixed"
                  style={{
                    maxWidth:
                      document.querySelector(".position-relative")
                        ?.offsetWidth || 380,
                    top: isScroll ? "1rem" : "unset",
                  }}
                >
                  <NewNewsList />

                  {isWeb && (
                    <div className="px-2 pt-4 pb-2 rounded-lg bg-white text-center w-100 border">
                      <img
                        className="w-50 mb-3"
                        src="/img/use_img/mobileSupport.png"
                        alt="subscribeMail"
                      />
                      <p className="h6 mb-3">
                        <span className="font-weight-900">
                          베스트관세법인 컨설팅본부
                        </span>
                        에서 발행하는
                        <br />
                        <span className="font-weight-900 text-danger">
                          관세와 물류에 관한 분석보고서
                        </span>
                        를 받아보세요. <br />
                        <span className="font-size-08">
                          단순 소식지가 아닌 전문가의 분석과 인사이트가 포함된
                          통관/물류 전문리포트입니다.
                        </span>
                      </p>
                      <div className="mb-3">
                        <input
                          className="form-control mb-1"
                          type="email"
                          placeholder="이메일 주소를 입력해주세요."
                          ref={controller.swrUserEmail}
                        />
                        <label className="d-flex flex-row align-items-center px-1">
                          <input
                            type="checkbox"
                            className="mr-2"
                            ref={controller.swrTermsYN}
                          />
                          <span className="text-danger font-weight-bold">
                            개인정보 수집 · 이용
                          </span>
                          에 동의해주세요.
                        </label>
                      </div>

                      <div className="d-flex flex-row justify-content-between align-items-end">
                        <a
                          className="text-secondary"
                          href="https://www.gbts.co.kr//backside/weeklyReport/cancel_concat_weekly_report.php"
                          target="_blank"
                        >
                          구독 취소
                        </a>
                        <button
                          className="btn btn-primary"
                          onClick={subscribeWeeklyReport}
                        >
                          무료로 구독하기
                          <i className="fa-regular fa-hand-pointer ml-2" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BestCustomsFooter />
    </main>
  );
}
