import React, { useEffect, useState } from "react";

import IMPAlarmTalkCompTable from "./IMPAlarmTalkCompTable";
import IMPAlarmTalkRegisteredCompTable from "./IMPAlarmTalkRegisteredCompTable";

let _selectCompList = [];

export default function IMPAlarmTalkManage(props) {
  const { userInfo, notiSet, saveUserNotiSet } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [selectedCompList, setSelectedCompList] = useState([]);

  useEffect(() => {
    _selectCompList = notiSet["IMP"];
    setSelectedCompList(notiSet["IMP"]);
  }, []);
  function selectComp(data) {
    const index = _selectCompList.findIndex(
      (comp) => comp.COMP_CD === data.COMP_CD
    );
    if (index > -1) {
      _selectCompList.splice(index, 1);
    } else {
      const presetData = notiSet["IMP"].find(
        (val) => val.COMP_CD === data.COMP_CD
      );
      _selectCompList = [
        ..._selectCompList,
        {
          ...data,
          STATE: presetData
            ? presetData["STATE"]
            : {
                CAGA01: 0,
                CAGA08: 0,
                CAGB01: 0,
                CAGB11: 0,
                CAGD01: 0,
                CAGE01: 0,
                CAGE12: 0,
                CAGG02: 0,
                IMPA01: 0,
                IMPA06: 0,
                IMPA17: 0,
                IMPA19: 0,
                IMPA21: 0,
                IMPA22: 0,
              },
        },
      ];
    }
    setIsNeedUpdate(!isNeedUpdate);
  }
  function addComp() {
    setSelectedCompList([..._selectCompList]);
  }

  function setCompAlarm(e, data) {
    let dataList = [...selectedCompList];
    const index = dataList.findIndex((comp) => comp.COMP_CD === data.COMP_CD);
    const [key1, key2] = e.target.name.split("-");
    if (dataList[index][key1] === undefined) {
      dataList[index][key1] = {
        CAGA01: 0,
        CAGA08: 0,
        CAGB01: 0,
        CAGB11: 0,
        CAGD01: 0,
        CAGE01: 0,
        CAGE12: 0,
        CAGG02: 0,
        B: 0,
        IMPA01: 0,
        IMPA06: 0,
        IMPA17: 0,
        IMPA19: 0,
        IMPA21: 0,
        IMPA22: 0,
      };
    }
    dataList[index][key1][key2] = e.target.checked ? 1 : 0;
    _selectCompList = [...dataList];
    setSelectedCompList([...dataList]);
  }

  function removeComp(data = []) {
    let _dataList = [];
    for (let i = 0; i < selectedCompList.length; i++) {
      const index = data.findIndex(
        (comp) => comp.COMP_CD === selectedCompList[i].COMP_CD
      );
      if (index < 0) {
        _dataList.push(selectedCompList[i]);
      }
    }
    _selectCompList = [..._dataList];
    setSelectedCompList([..._dataList]);
  }

  function handleNotiData() {
    let dataList = [];
    selectedCompList.forEach((data) => {
      dataList.push({
        COMPCD: data["COMP_CD"],
        COMP_CD: data["COMP_CD"],
        COMP_NM: data["COMP_NM"],
        COMP_NUM: data["COMP_NUM"],
        COMP_C_NUM: data["COMP_C_NUM"],
        STATE: data["STATE"],
      });
    });
    saveUserNotiSet("IMP", dataList);
  }

  return (
    <div className="h-100 bg-white d-flex flex-column">
      <div className="border-bottom py-3 px-2">
        <div className="d-flex flex-row justify-content-end align-items-center">
          <button
            className="logiwork-btn-color active"
            onClick={() => handleNotiData()}
          >
            저장하기
          </button>
        </div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-6 p-2">
          <IMPAlarmTalkCompTable
            userInfo={userInfo}
            notiSet={notiSet}
            _selectCompList={_selectCompList}
            selectComp={selectComp}
            addComp={addComp}
          />
        </div>
        <div className="col-6 p-2">
          <IMPAlarmTalkRegisteredCompTable
            selectedCompList={selectedCompList}
            setCompAlarm={setCompAlarm}
            removeComp={removeComp}
          />
        </div>
      </div>
    </div>
  );
}
