import React, { useRef } from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

const _mClassName = "MyInfoManage";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function MyInfoManage(props) {
  const { loginInfo } = props;
  const _controller = {
    USER_NM: useRef(),
    USER_TEL: useRef(),
    USER_NICK: useRef(),
    USER_PART_NM: useRef(),
    USER_TEAM_NM: useRef(),
    USER_PW: useRef(),
    USER_PW_CHK: useRef(),
  };

  function checkValidation() {
    if (_controller.USER_NM.current.value.trim() === "") {
      codeList.Modal.current.alert("이름을 입력해주세요.", () => {
        _controller.USER_NM.current.focus();
      });
      return true;
    }
    if (
      _controller.USER_PW.current.value !==
      _controller.USER_PW_CHK.current.value
    ) {
      codeList.Modal.current.alert("비밀번호가 일치하지 않습니다.", () => {
        _controller.USER_PW_CHK.current.focus();
      });
      return;
    }

    return false;
  }

  function actionSave() {
    if (checkValidation()) return;
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/user/JS_user_update_gbts.php",
      msgID: msgID,
      data: {},
      COMP_CD: loginInfo.comp_cd,
      USER_ID: loginInfo.user_id,
      USER_NM: _controller.USER_NM.current.value,
      USER_TEL: _controller.USER_TEL.current.value,
      USER_NICK: _controller.USER_NICK.current.value,
      USER_PART_NM: _controller.USER_PART_NM.current.value,
      USER_TEAM_NM: _controller.USER_TEAM_NM.current.value,
      USER_PW: _controller.USER_PW.current.value,
      USER_TALK_USE_YN: "Y",
      USE_GB: "A",
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (_controller.USER_PW.current.value.trim() !== "") {
            codeList.Modal.current.alert(
              "내 정보 및 비밀번호가 정상 변경 되었습니다. 다시 로그인해주세요.",
              () => {
                sessionStorage.removeItem("loginInfo");
                localStorage.removeItem("loginInfo");
                window.location.replace("/");
              }
            );
            return;
          }

          codeList.Modal.current.alert("내 정보가 정상 변경 되었습니다.");
          socket.sendLocalMessage(
            MsgIDList.EVENT_USER_MANAGE_DATA_UPDATED,
            _mClassName,
            newData,
            () => {}
          );
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 관리자에게 문의해주세요."
          );
        }
      }
    });
  }

  return (
    <div className="container-fluid">
      <div className="mb-2">
        <h5 className="font-weight-bold text-dark">내정보 관리</h5>
        <p className="font-weight-bold text-secondary">
          통합계정 (GBTS, LOGIWORK, LOGITALK)에 노출되는 내 정보를 수정합니다.
        </p>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 col-xl-5">
          <div className="card border-0 shadow-sm bg-white">
            <div className="p-2 border-bottom">
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">업체코드</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    value={loginInfo.comp_cd}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">아이디</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    value={loginInfo.user_id}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">
                  이름
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    ref={_controller.USER_NM}
                    defaultValue={loginInfo.user_nm}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">닉네임</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    ref={_controller.USER_NICK}
                    defaultValue={loginInfo.USER_NICK}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">연락처</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    ref={_controller.USER_TEL}
                    defaultValue={loginInfo.user_tel}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">업무부서</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    ref={_controller.USER_TEAM_NM}
                    defaultValue={loginInfo.USER_TEAM_NM}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">담당업무</div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="text"
                    className="form-control"
                    ref={_controller.USER_PART_NM}
                    defaultValue={loginInfo.USER_PART_NM}
                  />
                </div>
              </div>
            </div>
            <div className="p-2">
              <p className="text-danger">
                ※ 비밀번호 변경이 필요한 경우에만 입력해 주세요.
              </p>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">
                  비밀번호 변경
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="password"
                    className="form-control"
                    ref={_controller.USER_PW}
                  />
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-4 m-0 p-2">
                  비밀번호 변경 확인
                  <i style={{ color: "red", fontWeight: 700 }}>*</i>
                </div>
                <div className="col-8 m-0 p-2">
                  <input
                    type="password"
                    className="form-control"
                    ref={_controller.USER_PW_CHK}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-right mt-2">
            <button className="btn btn-primary" onClick={actionSave}>
              저장하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
