import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import BuddibleSocket from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
import CodeList from "../lib/CodeList";
import { useParams } from "react-router-dom";
import BestCustomsFooter from "./BestCustomsFooter";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function PublicReportDetail() {
  const [selectedReport, setSelectedReport] = useState(null);
  const params = useParams();

  useEffect(() => {
    requestNewsList();
  }, []);

  const requestNewsList = () => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/talk_news/JS_chatNewsTalkSelect.php",
      msgID: msgID,
      data: {},
      cntDelYN: "N",
      isLogiTalk: "Y",
      cntIDX: params.id,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setSelectedReport(newData["returnData"][0]);
        }
      }
    });
  };

  const [cntPicLink, cntPicName] = selectedReport
    ? selectedReport.cntPic.split("?")
    : [];
  const files =
    selectedReport && selectedReport.cntFile
      ? selectedReport.cntFile.split("|")
      : "";

  if (!selectedReport) return <></>;

  return (
    <main
      className="bg-white d-flex flex-column justify-content-between"
      style={{ minHeight: "100vh" }}
    >
      <div>
        <div className="container pt-4">
          <div className="d-flex flex-row justify-content-between align-items-center">
            <p className="h4 font-weight-900 text-dark">
              <img
                className="mr-2 cursor_pointer"
                style={{ height: "1em" }}
                src="https://gbts-invoice.s3.ap-northeast-2.amazonaws.com/gbts/companyInfo/20221207180941_베스트로고검정로고만.png"
                alt="logo"
                onClick={() => {
                  window.location.href = "http://www.bestcustoms.kr/";
                }}
              />
              컨설팅 본부 <span className="text-danger">주간 Report.</span>
            </p>
            <div>
              {selectedReport && (
                <span
                  className="cursor_pointer cursor_pointer_fw_bold h5 mb-0"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  뒤로가기 &times;
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="container py-3">
          <p className="h2 font-weight-bold mb-2">{selectedReport.cntTitle}</p>
          <p className="h6 font-weight-bold mb-3">
            작성일 :{" "}
            {util.getDateToFormat(selectedReport.cntCreatDate, "YYYY-MM-DD")}
          </p>
          <div className="height-350 mb-3 rounded-lg bg-light d-flex flex-row justify-content-center align-items-center overflow-hidden">
            <img
              src={cntPicLink}
              alt={cntPicName}
              style={{ width: "80%", marginTop: 80 }}
            />
          </div>
          <p className="h6 pb-3 mb-3">{selectedReport.cntMSG}</p>
          {selectedReport.cntLink !== "" && (
            <div className="h6 border-top py-3">
              <p className="font-weight-bold mb-2">※ 참조 링크</p>
              <p>
                <a
                  href={selectedReport.cntLink}
                  target="_blank"
                  className="underline"
                >
                  {selectedReport.cntLink}
                </a>
              </p>
            </div>
          )}
          {files !== "" && (
            <div className="h6 pt-3">
              <p className="font-weight-bold mb-3">※ 첨부파일</p>
              {files.map((file, index) => {
                const splitData = file.split("?");
                const fileLink = splitData[0];
                const fileName = splitData[1];

                return (
                  <p key={index} className="mb-2">
                    <span
                      className="d-inline-block mr-2 font-weight-bold cursor_pointer font-size-08"
                      onClick={() => {
                        codeList.Modal.current.confirm(
                          `[${fileName}]을 다운로드 하시겠습니까?`,
                          (ret) => {
                            if (ret) {
                              util.downloadAs(fileLink, fileName);
                            }
                          }
                        );
                      }}
                    >
                      💾 저장
                    </span>
                    <a href={fileLink} target="_blank">
                      {fileName}
                    </a>
                  </p>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <BestCustomsFooter />
    </main>
  );
}
