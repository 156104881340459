/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Pagination from "@mui/material/Pagination/Pagination";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import UserManageEdit from "./UserManageEdit";
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));
const headCells = [
  {
    field: "user_id",
    headerName: "아이디",
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,
  },
  {
    field: "user_nm",
    headerName: "이름",
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,
  },
  {
    field: "USER_TEL",
    headerName: "연락처",
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,
    renderCell: (params) => {
      return <span>{params.data || "-"}</span>;
    },
  },
  {
    field: "USER_TEAM_NM",
    headerName: "업무부서",
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,
    renderCell: (params) => {
      return <span>{params.data || "-"}</span>;
    },
  },
  {
    field: "USER_PART_NM",
    headerName: "담당업무",
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,

    renderCell: (params) => {
      return <span>{params.data || "-"}</span>;
    },
  },

  {
    field: "USER_TALK_USE_YN",
    headerName: "정보수정",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    onlyAdmin: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            key={"btn-" + params.currentData.uID}
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#UserEditModal"
            onClick={(e) => params.openEdit(e, params)}
            aria-label={"수정"}
          >
            <i className="fas fa-pen " />
          </button>
        </span>
      );
    },
  },

  {
    field: "use_gb",
    headerName: "계정 활성화",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    onlyAdmin: true,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            className={
              params.currentData.use_gb === "A"
                ? "btn btn-primary bg-freiscop border-freiscop rounded-0"
                : "btn btn-outline-secondary rounded-0"
            }
            onClick={(e) => params.changeUSEGB(params.currentData, "A")}
            aria-label={"수정"}
          >
            활성
          </button>
          <button
            type="button"
            className={
              params.currentData.use_gb !== "A"
                ? "btn btn-primary bg-freiscop border-freiscop rounded-0"
                : "btn btn-outline-secondary rounded-0"
            }
            onClick={(e) => params.changeUSEGB(params.currentData, "D")}
            aria-label={"비활성"}
          >
            비활성{" "}
          </button>
        </span>
      );
    },
  },
];
const _mClassName = "UserManageTable";

function Row(props) {
  const { row, loginInfo, openEdit, changeUSEGB } = props;
  const classes = useRowStyles();

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      {headCells.map((headCell) => {
        if (headCell.onlyAdmin && !loginInfo.isCorpAdmin) return <></>;

        return (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"} //text 정렬
            padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
          >
            {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  changeUSEGB: changeUSEGB,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  changeUSEGB: changeUSEGB,
                })
              : row[headCell.field]}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

Row.propTypes = {
  row: PropTypes.shape({}).isRequired,
};

function EnhancedTableHead(props) {
  const { loginInfo, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.onlyAdmin && !loginInfo.isCorpAdmin) return <></>;
          return (
            <TableCell
              key={headCell.field}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.field ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.field}
                direction={orderBy === headCell.field ? order : "asc"}
                onClick={createSortHandler(headCell.field)}
              >
                {headCell.headerName}
                {orderBy === headCell.field ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCell: PropTypes.objectOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
      headerName: PropTypes.string.isRequired,
    })
  ),
};

export default function UserManageTable({
  isServerPaging = true,
  isServerSort = true,
  loginInfo,
}) {
  const classes = useStyles();
  const [serverData, setServerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_nm");

  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(false);
  const editModal = useRef();

  const [sortModel, setSortModel] = useState([
    {
      field: "user_nm",
      sort: "asc",
    },
  ]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_USER_MANAGE_DATA_UPDATED,
      _mClassName,
      (b, n) => {
        requestUserSelect();
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_USER_MANAGE_DATA_UPDATED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, sortModel, filters]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/user/JS_user_select_gbts.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      comp_cd: loginInfo.comp_cd,
      user_id: loginInfo.user_id,
      ...filters,
    };
    setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        sort: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  function openEdit(e, params) {
    editModal.current.openModal(e, params);
  }

  // 계정활성화 여부 변경
  const changeUSEGB = (userData, use_gb) => {
    if (!userData || !userData.hasOwnProperty("user_id")) {
      codeList.Modal.current.alert("오류가 발생하였습니다. 다시 시도해주세요");
    }
    const updateData = {
      ...userData,
      COMP_CD: userData.comp_cd,
      USER_ID: userData.user_id,
      USER_NM: userData.user_nm,
      USE_GB: use_gb,
    };

    if (use_gb === "D") {
      codeList.Modal.current.confirm(
        "해당 계정을 비활성하시면 해당 아이디는 사용할 수 없습니다. 계속 하시겠습니까?",
        (ret) => {
          if (ret) {
            UPDATE_USE_GB(updateData);
          }
        }
      );
    } else {
      UPDATE_USE_GB(updateData);
    }
  };

  const UPDATE_USE_GB = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/user/JS_user_update_gbts.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "계정 활성화 상태가 정상적으로 변경되었습니다."
          );
          requestUserSelect();
        } else {
          codeList.Modal.current.alert(
            "오류가 발생하였습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <EnhancedTableHead
          loginInfo={loginInfo}
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={data.length}
        />

        <TableBody>
          {!isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  loginInfo={loginInfo}
                  openEdit={openEdit}
                  changeUSEGB={changeUSEGB}
                />
              ))}
          {!isServerPaging &&
            isServerSort &&
            util
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  loginInfo={loginInfo}
                  openEdit={openEdit}
                />
              ))}

          {isServerPaging &&
            !isServerSort &&
            util
              .stableSort(data, util.getComparator(order, orderBy))
              .map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  loginInfo={loginInfo}
                  openEdit={openEdit}
                />
              ))}

          {isServerPaging &&
            isServerSort &&
            data.map((row, index) => (
              <Row
                key={index}
                row={row}
                index={index}
                loginInfo={loginInfo}
                openEdit={openEdit}
                changeUSEGB={changeUSEGB}
              />
            ))}

          {loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div className="spinner-grow text-secondary" role="status" />
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length === 0 && !loading && (
            <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
              <TableCell colSpan={headCells.length}>
                <div className="d-flex justify-content-center">
                  <div>해당 데이터가 존재하지 않습니다.</div>
                </div>
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 && emptyRows > 0 && (
            <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
              <TableCell colSpan={headCells.length} />
            </TableRow>
          )}
        </TableBody>
      </Table>

      <div className="mb-3 mt-3 pr-3">
        <Pagination
          style={{ justifyContent: "flex-end" }}
          className={classes.pagination}
          color="primary"
          count={totalPageCount}
          page={page + 1}
          allin={"right"}
          onChange={(event, value) => {
            handleChangePage(event, value - 1);
          }}
          showFirstButton
          showLastButton
        />
      </div>
      <UserManageEdit
        ref={editModal}
        userData={{ name: "aa" }}
        requestUserSelect={requestUserSelect}
        loginInfo={loginInfo}
      />
    </TableContainer>
  );
}

UserManageTable.propTypes = {
  newData: PropTypes.objectOf(
    PropTypes.shape({
      pageSize: PropTypes.string,
    })
  ),
  editModal: PropTypes.objectOf(
    PropTypes.shape({
      current: PropTypes.objectOf(
        PropTypes.shape({
          openModal: PropTypes.func,
        })
      ),
    })
  ),
  openModal: PropTypes.func,
  pageSize: PropTypes.string,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
};
