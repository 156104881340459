import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";

import GBTSLogoSvg from "../../assets/GBTSLogoSvg";

import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;
const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function FindPW() {
  const [idChk, setIdChk] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const $controllers = {
    COMP_CD: useRef(),
    USER_ID: useRef(),
  };

  const CheckEmail = (str) => {
    let reg_email =
      /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
    return reg_email.test(str);
  };

  const checkValidation = () => {
    if ($controllers.COMP_CD.current.value.trim() === "") {
      codeList.Modal.current.alert("업체코드를 입력 해주세요.", () => {
        $controllers.COMP_CD.current.focus();
      });
      return false;
    }

    if ($controllers.USER_ID.current.value.trim() === "") {
      codeList.Modal.current.alert(
        "아이디로 사용된 이메일을 입력 해주세요.",
        () => {
          $controllers.USER_ID.current.focus();
        }
      );
      return false;
    }

    if (!idChk) {
      codeList.Modal.current.alert("옳바르지 않은 이메일 형식입니다.", () => {
        $controllers.USER_ID.current.focus();
      });
      return false;
    }

    return true;
  };

  const requestUserUpdate = () => {
    let msgID = util.makeUUIDv4();
    if (checkValidation() === false) return;

    let socketMsg = {
      file: "/join/JS_join_gbts_comp_user_find_pw.php",
      msgID: msgID,
      data: {},
      COMP_CD: util.buddibleEncrypt($controllers.COMP_CD.current.value),
      USER_ID: util.buddibleEncrypt($controllers.USER_ID.current.value),
    };

    setIsLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "등록된 메일 주소로 임시비밀번호가 전송되었습니다. 보안을 위해 로그인 후 비밀번호 변경을 진행해주세요.",
            () => {
              if (window.location.hash === "#logiwork") {
                window.location.replace("https://system.gbts.co.kr/login.do");
              }
            }
          );
        } else {
          codeList.Modal.current.alert(
            newData["MSG"] || "오류가 발생했습니다. 관리자에게 문의해주세요."
          );
        }

        setIsLoading(false);
      }
    });
  };

  return (
    <div className="vh-100 d-flex flex-column align-items-center justify-content-center bg-light">
      <GBTSLogoSvg height={60} />
      <p className="mt-2 mb-3 h5 font-weight-bold text-secondary">
        통합회원(GBTS, LOGIWORK, LOGIKEEP) 비밀번호 찾기
      </p>
      <div className="bg-white p-2 rounded shadow-sm" style={{ minWidth: 700 }}>
        <div className="container-fluid">
          <div className="py-3 d-block">
            <div className="mb-3 row">
              <label className="col-form-label col-sm-3 text-end">
                업체코드
              </label>
              <div className="col-sm-9">
                <input
                  type="text"
                  disabled={isLoading}
                  className="form-control"
                  ref={$controllers.COMP_CD}
                />
              </div>
            </div>

            <div className="row mb-3">
              <label className="col-form-label col-sm-3 text-end">이메일</label>
              <div className="col d-flex flex-row">
                <input
                  className="form-control"
                  type="email"
                  disabled={isLoading}
                  ref={$controllers.USER_ID}
                  onChange={(e) => {
                    setIdChk(CheckEmail(e.target.value));
                  }}
                />
              </div>
            </div>

            {!idChk ? (
              <div className="mb-3 row" style={{ marginTop: -10 }}>
                <label className="col-form-label col-sm-3 text-end"></label>
                <div className="col-sm-9" style={{ textAlign: "left" }}>
                  <span style={{ color: "#f64a4a" }}>
                    올바른 이메일 주소를 입력해주세요
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}
            <p className="text-danger">
              ※ 아이디가 유효하지 않는 이메일주소의 경우 관리자에게
              문의해주세요.
            </p>
          </div>
          <div className="text-right mb-2">
            <button
              type="button"
              className="btn_1"
              disabled={isLoading}
              onClick={() => {
                requestUserUpdate();
              }}
            >
              임시비밀번호 전송
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ml-2"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
