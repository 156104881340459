/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import CryptoJS from "crypto-js";
import { useParams, useLocation } from "react-router-dom";
import QueryString from "qs";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../lib/BuddibleSocket";
import Utilities from "../lib/Utilities";
const util = new Utilities();
const socket = new BuddibleSocket();

/*window.jQuery = $;
window.$ = $;
global.jQuery = $;*/
//U2FsdGVkX18U/58TA/vXgflCHtSytucJ08aMu0i90Rc=@U2FsdGVkX1+YlDkfmjKqAn28SZnKXMcFHMOFA2hsQZ4=

export default function Footer() {
  const param = useParams();
  const location = useLocation();

  const data = {
    username: "Jinho Kim",
    age: 25,
  };
  //console.log(param,location);
  const queryData = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  //console.log(queryData);

  const encSite = "U2FsdGVkX18U/58TA/vXgflCHtSytucJ08aMu0i90Rc=";
  const encID = "U2FsdGVkX1+YlDkfmjKqAn28SZnKXMcFHMOFA2hsQZ4=";

  // 복호화 키 지정
  const privateKey = "LOGINS";
  // AES알고리즘 사용 암호화
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    privateKey
  ).toString();
  // AES알고리즘 사용 복호화 ( 복구 키 필요 )
  const bytes = CryptoJS.AES.decrypt(encrypted, privateKey);
  // 인코딩, 문자열로 변환, JSON 변환
  const decrypted = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  /*
  console.log(encrypted,bytes,decrypted)
  console.log(CryptoJS.AES.decrypt(encSite, privateKey).toString(CryptoJS.enc.Utf8));
  console.log(CryptoJS.AES.decrypt(encID, privateKey).toString(CryptoJS.enc.Utf8));*/

  let $this = null;
  let $prev = null;
  const $win = $(window);

  const footerReveal = (obj, options) => {
    $this = obj;
    $prev = $this.prev();

    let defaults = $.extend(
        {
          shadow: true,
          shadowOpacity: 0.8,
          zIndex: -100,
        },
        options
      ),
      settings = $.extend(true, {}, defaults, options);

    if ($this.outerHeight() <= $win.outerHeight()) {
      $this.css({
        "z-index": defaults.zIndex,
        position: "fixed",
        bottom: 0,
      });

      if (defaults.shadow) {
        $prev.css({
          "-moz-box-shadow":
            "0 20px 30px -20px rgba(0,0,0," + defaults.shadowOpacity + ")",
          "-webkit-box-shadow":
            "0 20px 30px -20px rgba(0,0,0," + defaults.shadowOpacity + ")",
          "box-shadow":
            "0 20px 30px -20px rgba(0,0,0," + defaults.shadowOpacity + ")",
        });
      }
    }
  };

  const handleResize = () => {
    if ($this !== null) {
      $this.css({
        width: $prev.outerWidth(),
      });
      $prev.css({
        "margin-bottom": $this.outerHeight(),
      });
    }
  };

  useEffect(() => {
    // console.log("footerReveal");

    window.addEventListener("resize", handleResize);

    setTimeout(() => {
      footerReveal(window.$("footer.revealed"), {
        shadow: false,
        opacity: 0.6,
        zIndex: 0,
      });
      handleResize();
    }, 300);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //로그인정보 전송
  const requestLogin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/talk/JS_gbts_user_list.php",
      msgID: msgID,
      data: {},
      take: 10,
      skip: 0,
      page: 1,
      pageSize: 5,
      sort: [
        {
          field: "USER_ID",
          sort: "asc",
        },
      ],
      COMP_CD: "B0004",
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      // console.log(newData);
    });
  };

  return (
    <>
      <footer
        className="revealed"
        style={{ minWidth: "100vw", background: "#131638" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h3>GBTS 고객센터</h3>
              <a id="phone" href="tel:032-751-9880">
                TEL : 032-751-9880
              </a>
              <ul>
                <li>
                  <span>㈜지비티에스 대표 : 서창길</span>
                </li>
                <li>
                  <span>FAX : 032-751-9865 , E-mail: gbts@bstc.kr</span>
                </li>
                <li>
                  <span>사업자등록번호 : 693-81-01474</span>
                </li>
                <li>
                  <span>통신판매신고번호 : 제2020-인천중구-0070호</span>
                </li>
                <li>
                  <span>인천광역시 중구 흰바위로59번길8, 220호 (운서동)</span>
                </li>
              </ul>
            </div>
            <div className="col-md-2" />
            <div className="col-md-3">
              <h3>GBTS</h3>
              <ul>
                <li>
                  <a href="http://home.gbts.co.kr/" target="_blank">
                    로지웍 소개
                  </a>
                </li>
                <li>
                  <a href="http://system.gbts.co.kr/" target="_blank">
                    로지웍 바로가기
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <h3>협력사</h3>
              <ul>
                <li>
                  <a href="https://cardlogis.com" target="_blank">
                    카드로지스
                  </a>
                </li>
                <li>
                  {/*<a href="https://cardlaw.co.kr" target="_blank">*/}
                  <a href="http://cardlaw.co.kr" target="_blank">
                    카드로
                  </a>
                </li>
                <li>
                  <a
                    href="https://iamforwarder.gbts.co.kr/iamforwarder"
                    target="_blank"
                  >
                    아이엠포워더
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div id="social_footer" className="pt-2">
                <p>
                  Copyright GBTS Corp. &copy; All Rights Reserved. &{" "}
                  <a href="https://clearbit.com">Logos provided by Clearbit</a>
                </p>{" "}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
