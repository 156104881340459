import React from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";

let socket = new BuddibleSocket();
let util = new Utilities();

export default class CodeList {
  constructor(data) {
    if (CodeList.exists) {
      return CodeList.instance;
    }
    CodeList.instance = this;
    CodeList.exists = true;
    this.codeCountryList = [];

    this.codeCountry = [];
    this.codeCountryFullName = [];
    this.codeAirCountryFullName = [];
    this.codeLanguage = new Map();
    this.codeSubject = new Map();
    this.codeHashtag = new Map();
    this.codeHashtagObj = [];
    this.codeHashtagByName = new Map();
    this.codeOffer = new Map();

    this.publicVar = {
      imgServerUrl: "https://msgserver.buddible.com:4000",
      days: [
        { value: "0", name: "일" },
        { value: "1", name: "월" },
        { value: "2", name: "화" },
        { value: "3", name: "수" },
        { value: "4", name: "목" },
        { value: "5", name: "금" },
        { value: "6", name: "토" },
      ],
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      tableDefaultStyle: (theme) => ({
        root: {
          width: "100%",
        },
        paper: {
          width: "100%",
          marginBottom: theme.spacing(2),
        },
        table: {
          minWidth: 750,
        },
        visuallyHidden: {
          border: 0,
          clip: "rect(0 0 0 0)",
          height: 1,
          margin: -1,
          overflow: "hidden",
          padding: 0,
          position: "absolute",
          top: 20,
          width: 1,
        },
        pagination: {
          "& ul": {
            justifyContent: "flex-end",
          },
          "& button": {
            borderRadius: "100%",
          },
        },
      }),
      tableRowDefaultStyle: () => ({
        root: {
          "& > *": {
            padding: 11,
          },
        },
      }),
    };
    this.Modal = React.createRef();

    this.setModal = (e) => {
      CodeList.instance.Modal.current = e;
    };

    return this;
  }
  requestHashtagList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      method: "GetHashtagList",
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          this.codeHashtagObj = [];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            this.codeHashtag.set(obj.htc_key, obj.htc_name);
            this.codeHashtagByName.set(obj.htc_name, obj.htc_key);
            this.codeHashtagObj.push({
              htc_key: obj.htc_key,
              htc_name: obj.htc_name,
            });
          }
          if (callback) callback(true);
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_HASHTAG,
            "InitCodeList",
            this.codeHashtag,
            () => {}
          );
        }
      }
    });
  };
}

export const CommonConstList = {
  Modal: {
    TYPE_ALERT: "TYPE_ALERT",
    TYPE_CONFIRM: "TYPE_CONFIRM",
    TYPE_INPUT: "TYPE_INPUT",
  },
  RouteOffer: {
    OFFER_FOOD: "OFFER_FOOD",
    OFFER_DRINK: "OFFER_DRINK",
    OFFER_TICKET: "OFFER_TICKET",
    OFFER_EQUIP: "OFFER_EQUIP",
    OFFER_DRIVER: "OFFER_DRIVER",
  },
  Accessibility: {
    ACCESS_MAIN: "ACCESS_MAIN",
    ACCESS_ETC: "ACCESS_ETC",
    ACCESS_COMM: "ACCESS_COMM",
    ACCESS_ENV: "ACCESS_ENV",
  },
};
